.container {
  display: block;
  padding: 0px 15px 0px 15px;
  position: static;
  background-color: #008080;
}

.logo {
height: 55px;
}

.imglogo {
  height: 47px;
  margin: 2px 2px 2px 2px;
}

.navbar {
  background: #58249c;
  padding: 20px;
  color: #fff;
}

.container nav {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

.textlink {
  font-size: 14px;
  color: white;
}
