/* .main {
  height: auto; 
  min-height: 100%;
} */

.App {
    min-height: 100vh;
    /*background-image: url("/public/patternblue.png");*/
    /*height: 100vh;*/
}

.App2 {
    min-height: 100vh;
    background-image: url("/public/pisa-back-2.jpeg");
}


body {
    /*background-image: "http://core2022.evaluasikla.id/img/merge-pattern blue.png"; */
    background-color: #ECF0F5;
}